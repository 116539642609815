/* Chat Widget Container */
.chat-widget-container {
  position: relative;
}

/* Chat Widget Button */
.chat-widget {
  position: fixed;
  bottom: 20px;
  right: 20px;

  .chat-button {
    background-color: #f0542d; // Main color
    color: white;
    border: none;
    border-radius: 50%;
    padding: 15px;
    cursor: pointer;
    font-size: 16px;
  }
}

/* Chat Section */
.chat-section {
  position: fixed;
  bottom: 80px; // Adjusted to avoid overlap with the button
  right: 20px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  max-height: 800px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  overflow-y: auto;

  .chat-section-content {
    padding: 20px;

    .close {
      color: #aaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
      cursor: pointer;

      &:hover,
      &:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
      }
    }

    h2 {
      margin-top: 0;
      font-size: 18px;
    }

    form {
      display: flex;
      flex-direction: column;

      label {
        margin-top: 10px;
        font-size: 14px;
      }

      input,
      textarea {
        margin-top: 5px;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 14px;
      }

      .phone-input {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        select {
          margin-top: 5px;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 5px;
          width: 100%;
          font-size: 14px;
        }

        input {
          margin-top: 5px;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 5px;
          width: 100%;
          font-size: 14px;
        }
      }

      button {
        margin-top: 15px;
        padding: 10px;
        background-color: #f0542d; // Main color
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;

        &:hover {
          background-color: #d03c1f;
        }
      }
    }
  }
}

/* Responsive Styles */
@media (max-width: 600px) {
  .chat-widget {
    bottom: 10px;
    right: 10px;

    .chat-button {
      padding: 10px;
      font-size: 14px;
    }
  }

  .chat-section {
    bottom: 60px; // Adjust to avoid overlap with the button
    right: 10px;
    max-width: calc(100% - 20px);

    .chat-section-content {
      padding: 15px;

      .close {
        font-size: 24px;
      }

      h2 {
        font-size: 16px;
      }

      form {
        font-size: 14px;

        input,
        textarea {
          font-size: 14px;
        }

        button {
          font-size: 14px;
        }
      }
    }
  }
}
